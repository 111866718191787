import React from "react";
import SignupOptionsWithTag from "../../Components/organisms/SignupOptionsWithTag/SignupOptionsWithTag";

const SignupWithTag = () => {
  return (
    <div>
      <SignupOptionsWithTag />
    </div>
  );
};

export default SignupWithTag;
